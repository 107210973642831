<template>
  <div>
    <div class="product-variables">
      <div class="product-price" v-if="showPrice">
        <!-- Preu regular  -->
        <span class="product-price__regular" v-if="showRegularPrice">
          <bdi>{{ textPrice }}</bdi>
        </span>
        <!-- Preu amb oferta -->

        <span class="product-price__sale" v-else>
          <del>{{ textCompareAtPrice }}</del>
          <bdi>{{ textPrice }}</bdi>
        </span>
      </div>

      <template v-if="severalOptions">
        <section v-for="(option, index) in options" :key="index" class="variable">
          <div class="variable__title">
            <span>{{ option.name }}:</span>
            {{ option.values.filter((x) => x.isSelected)[0].value }}
          </div>
          <div class="variable__content">
            <div class="variable-label">
              <div
                v-for="({ value, imageUrl, isSelected }, index) in option.values"
                :key="index"
                @click="selectOption(option, value)"
                :class="[
                  isSelected ? 'variable-pic__item--active' : '',
                  imageUrl ? 'variable-pic__item' : '',
                ]"
              >
                <img
                  v-if="imageUrl"
                  :src="imageUrl || '/hellobb.jpg'"
                  :alt="value"
                  v-tooltip="tooltip(value)"
                />
                <div v-else class="variable-label__item">{{ value }}</div>
              </div>
            </div>
          </div>
        </section>
      </template>
    </div>

    <div class="product-qty" v-if="showQuantity">
      <div class="product-qty__title">Cantidad</div>
      <div class="product-qty__content">
        <div class="qty">
          <div class="qty__less">
            <button class="button button--outline-dark button--sm" @click="decrease()">
              -
            </button>
          </div>
          <div class="qty__qty">{{ selectedQuantity }}</div>
          <div class="qty__more">
            <button class="button button--outline-dark button--sm" @click="increase()">
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "floating-vue/dist/style.css";
import { mapGetters } from "vuex";
import { VTooltip } from "floating-vue";
export default {
  name: "ProductOptions",
  directives: {
    tooltip: VTooltip,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    showPrice: {
      type: Boolean,
      default: true,
    },
    showQuantity: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
      product: {
      handler(newValue) {
        if (newValue) {
          if (this.product.shopifyProductVariantId) {
            const selectedVariant = this.product.variants.find(
              (variant) =>
                parseInt(variant.shopifyId) === parseInt(this.product.shopifyProductVariantId)
            );
            this.$store.commit(
              "setSelectedOptions",
              selectedVariant.optionValues.map((optionValue) => {
                return { name: optionValue.optionName, value: optionValue.optionValue };
              })
            );
          } else if (this.product.variantId) {
            console.log("variantId", this.product.variantId);
            const selectedVariant = this.product.variants.find(
              (variant) => parseInt(variant.id) === parseInt(this.product.variantId)
            );
            this.$store.commit(
              "setSelectedOptions",
              selectedVariant.optionValues.map((optionValue) => {
                return { name: optionValue.optionName, value: optionValue.optionValue };
              })
            );
          } else {
            // Select the variant with the lowest price, but with availabeForSale = true
            let minPriceVariant = this.product.variants
              .filter((variant) => variant.availableForSale)
              .sort((a, b) => a.price - b.price)[0];

            if (!minPriceVariant) {
              // If there is no variant with availabeForSale = true, select the variant with the lowest price
              minPriceVariant = this.product.variants?.reduce((prev, curr) =>
                prev.price <= curr.price ? prev : curr
              );
            }

            this.$store.commit(
              "setSelectedOptions",
              minPriceVariant.optionValues.map((optionValue) => {
                return { name: optionValue.optionName, value: optionValue.optionValue };
              })
            );
          }
        }
      },
      // force eager callback execution
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      selectedOptions: "selectedOptions",
      selectedVariant: "selectedVariant",
      selectedQuantity: "selectedQuantity",
    }),
    severalOptions() {
      var b = this.options.length == 1 && this.options[0].values.length == 1;
      return !b;
    },
    showRegularPrice() {
      return this.selectedVariant?.compareAtPrice === 0;
    },
    textPrice() {
      return this.$utils.formatMoney(this.selectedVariant?.price);
    },
    textCompareAtPrice() {
      return this.$utils.formatMoney(this.selectedVariant?.compareAtPrice);
    },
    options() {
      let options = Array.isArray(this.product.options) ? this.product.options : [];

      this.selectedOptions.forEach((selectedOption) => {
        options.map((originalOption) => {
          const newOption = options.find((option) => option.name === selectedOption.name);
          if (newOption) {
            newOption.values.map((val) => {
              if (val.value === selectedOption.value) {
                val.isSelected = true;
              } else {
                val.isSelected = false;
              }
              return val;
            });
            return newOption;
          }
          return originalOption;
        });
      });

      return options;
    },
  },
  methods: {
    selectOption(option, value) {
      if (this.readOnly) return;
      const index = this.selectedOptions?.findIndex((selectedOption) => {
        return selectedOption.name === option.name;
      });
      const newOption = { ...this.selectedOptions[index] };
      newOption.value = value;
      this.$store.commit("setSelectedOptionsByIndex", { vm: this, index, newOption });
    },
    decrease() {
      if (this.selectedQuantity > 1)
        this.$store.commit("setSelectedQuantity", this.selectedQuantity - 1);
    },
    increase() {
      this.$store.commit("setSelectedQuantity", this.selectedQuantity + 1);
    },
    tooltip(value) {
      return {
        html: true,
        content: value,
        handleResize: false,
        triggers: ["hover", "focus", "click"],
      };
    },
  },
  created() {
    //compare with route id
    if (this.product.id == this.$route.params.id) {
      if (this.product.shopifyProductVariantId) {
        const selectedVariant = this.product.variants.find(
          (variant) =>
            parseInt(variant.shopifyId) === parseInt(this.product.shopifyProductVariantId)
        );
        this.$store.commit(
          "setSelectedOptions",
          selectedVariant.optionValues.map((optionValue) => {
            return { name: optionValue.optionName, value: optionValue.optionValue };
          })
        );
      } else if (this.product.variantId) {
        console.log("variantId", this.product.variantId);
        const selectedVariant = this.product.variants.find(
          (variant) => parseInt(variant.id) === parseInt(this.product.variantId)
        );
        this.$store.commit(
          "setSelectedOptions",
          selectedVariant.optionValues.map((optionValue) => {
            return { name: optionValue.optionName, value: optionValue.optionValue };
          })
        );
      } else {
        // Select the variant with the lowest price, but with availabeForSale = true
        let minPriceVariant = this.product.variants
          .filter((variant) => variant.availableForSale)
          .sort((a, b) => a.price - b.price)[0];

        if (!minPriceVariant) {
          // If there is no variant with availabeForSale = true, select the variant with the lowest price
          minPriceVariant = this.product.variants?.reduce((prev, curr) =>
            prev.price <= curr.price ? prev : curr
          );
        }

        this.$store.commit(
          "setSelectedOptions",
          minPriceVariant.optionValues.map((optionValue) => {
            return { name: optionValue.optionName, value: optionValue.optionValue };
          })
        );
      }
    }
  },
  mounted() {
    this.$store.commit("setSelectedQuantity", 1);
  },
};
</script>
